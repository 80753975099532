@import "variables";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width:100%;
  overflow: hidden;
}
body {
  margin: 0;
}

body p,
dl,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 8px 0;
  font-weight: 400 !important;
  color: $dark-blue;
}

textarea {
  min-height: 100px;
  max-height: 100%;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #38424c;
}

.d-flex {
  display: flex !important;
}
.d-none {
  display: none;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row;
  display: flex;
}
.flex-column {
  flex-direction: column;
  display: flex;
}
.flex-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-flex-start {
  justify-content: flex-start;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center !important;
}

.align-self-flex-start {
  align-self: flex-start;
}
.align-self-flex-end {
  align-self: flex-end;
}

.f-left {
  float: left;
}
.f-right {
  float: right;
}

.pos-relative {
  position: relative;
}
.pos-absolute {
  position: absolute;
}

.user-select-none {
  user-select: none;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-align-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

/* Stuff */

// Changes Default "Margin" Measures. Ex: class="mb-3" => 'm'argin-'b'ottom: 20px;
.m,
.mt,
.my {
  &-auto {
    margin-top: auto !important;
  }
  &-0 {
    margin-top: 0 !important;
  }
  &-1 {
    margin-top: 0.5rem !important;
  }
  &-2 {
    margin-top: 1rem !important;
  }
  &-3 {
    margin-top: 1.5rem !important;
  }
  &-4 {
    margin-top: 2rem !important;
  }
  &-5 {
    margin-top: 3rem !important;
  }
}
.m,
.mb,
.my {
  &-auto {
    margin-bottom: auto !important;
  }
  &-0 {
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-bottom: 0.5rem !important;
  }
  &-2 {
    margin-bottom: 1rem !important;
  }
  &-3 {
    margin-bottom: 1.5rem !important;
  }
  &-4 {
    margin-bottom: 2rem !important;
  }
  &-5 {
    margin-bottom: 3rem !important;
  }
}
.m,
.mr,
.mx {
  &-auto {
    margin-right: auto !important;
  }
  &-0 {
    margin-right: 0 !important;
  }
  &-1 {
    margin-right: 0.5rem !important;
  }
  &-2 {
    margin-right: 1rem !important;
  }
  &-3 {
    margin-right: 1.5rem !important;
  }
  &-4 {
    margin-right: 2rem !important;
  }
  &-5 {
    margin-right: 3rem !important;
  }
}
.m,
.ml,
.mx {
  &-auto {
    margin-left: auto !important;
  }
  &-0 {
    margin-left: 0 !important;
  }
  &-1 {
    margin-left: 0.5rem !important;
  }
  &-2 {
    margin-left: 1rem !important;
  }
  &-3 {
    margin-left: 1.5rem !important;
  }
  &-4 {
    margin-left: 2rem !important;
  }
  &-5 {
    margin-left: 3rem !important;
  }
}

// Changes Default "Padding" Measures. Ex: class="pb-3" => 'p'adding-'b'ottom: 20px;
.p,
.pt,
.py {
  &-0 {
    padding-top: 0 !important;
  }
  &-1 {
    padding-top: 0.5rem !important;
  }
  &-2 {
    padding-top: 1rem !important;
  }
  &-3 {
    padding-top: 1.5rem !important;
  }
  &-4 {
    padding-top: 2rem !important;
  }
  &-5 {
    padding-top: 3rem !important;
  }
}
.p,
.pb,
.py {
  &-0 {
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-bottom: 0.5rem !important;
  }
  &-2 {
    padding-bottom: 1rem !important;
  }
  &-3 {
    padding-bottom: 1.5rem !important;
  }
  &-4 {
    padding-bottom: 2rem !important;
  }
  &-5 {
    padding-bottom: 3rem !important;
  }
}
.p,
.pr,
.px {
  &-0 {
    padding-right: 0 !important;
  }
  &-1 {
    padding-right: 0.5rem !important;
  }
  &-2 {
    padding-right: 1rem !important;
  }
  &-3 {
    padding-right: 1.5rem !important;
  }
  &-4 {
    padding-right: 2rem !important;
  }
  &-5 {
    padding-right: 3rem !important;
  }
}
.p,
.pl,
.px {
  &-0 {
    padding-left: 0 !important;
  }
  &-1 {
    padding-left: 0.5rem !important;
  }
  &-2 {
    padding-left: 1rem !important;
  }
  &-3 {
    padding-left: 1.5rem !important;
  }
  &-4 {
    padding-left: 2rem !important;
  }
  &-5 {
    padding-left: 3rem !important;
  }
}

@for $i from 9 through 100 {
  .fs-#{$i} {
    font-size: 1px * $i;
  }
}

@for $i from 0 through 11 {
  .w-#{$i} {
    width: 10% * $i;
  }
}

@for $i from 0 through 11 {
  .h-#{$i} {
    height: 10% * $i;
  }
}

.full-heigth {
  height: 100%;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.bold {
  font-weight: 500 !important;
}

.extra-bold {
  font-weight: 900 !important;
}

.dark-blue {
  color: $dark-blue;
}
.primary-color-light {
  color: $primary-color-light;
}
.mat-success {
  background-color: $success-color !important;
  color: $white !important;
}

.primary-background-color {
  background-color: $dark-blue;
}

.accent-background-color {
  background-color: $light-blue;
}

.error-background-color {
  background-color: $error-color;
}

.success-background-color {
  background-color: $success-color;
}

.primary-color {
  color: $dark-blue;
}

.accent-color {
  color: $light-blue;
}

.error-color {
  color: $error-color;
}

.success-color {
  color: $success-color;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: $light-grey;
}

::-webkit-scrollbar-thumb {
  background: #AEB6BF;
}

scrollbar-color table {
  width: 100%;
  font-weight: 300 !important;
}

/* MAT COMPONENTS */
.mat-form-field {
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 14px;
  width: 100%;
  font-weight: 300 !important;
}

.mat-button-toggle-group {
  display: flex !important;
  margin-bottom: 8px;
  margin-top: 8px;
  .mat-button-toggle-focus-overlay {
    display: none;
  }
}

.mat-button-toggle {
  width: 100%;
}

.mat-form-field-flex {
  background-color: unset !important;
}

mat-card {
  width: 100%;
}

mat-chip {
  font-weight: 300 !important;
}

.mat-badge-content {
  top: 0px !important;
}

/* MAT TAB*/
.mat-tab-label,
.mat-tab-link {
  color: $dark-blue !important;
}

.mat-table {
  width: 100%;
}

.mat-tab-label.mat-tab-label-active {
  color: $dark-blue !important;
}

/* MAT MENU */
.mat-menu-item-submenu-trigger::after {
  color: $dark-blue !important;
}

.mat-menu-item .mat-icon {
  color: $dark-blue !important;
}

.link {
  cursor: pointer;
  color: $light-blue;
  text-decoration: none;
}

/* mouse over link */
.link:hover {
  color: $dark-blue;
}

#last-input .mat-form-field-wrapper {
  padding-bottom: 0px;
}

#last-input .mat-form-field-underline {
  padding-bottom: 0px;
  position: initial;
}

.no-data {
  width: 100%;
  text-align: center;

  mat-icon {
    font-size: 100px !important;
    color: $grey !important;
    width: fit-content;
    height: fit-content;
  }
}

@media only screen and (max-width: 600px) {
  .mat-button-toggle-group {
    flex-wrap: wrap !important;
  }
}

.bold-title {
  font-weight: 600 !important;
}
