$dark-blue: #07283a;
$gov-blue: #3C5DBC;
$light-blue: #51b2f4;
$error-color: #f26262;
$warn-color: #e1be42;
$success-color: #a0d65c;
$orange-color: #F47A37;
$pink-color: #FC92E5;
$aqua-green-color: #60D8A4;

$white: #ffffff;
$black: #000000;

$primary-color-light: #bbdefb;
$light-text: #ffffff;
$dark-text: #07283a;
$secondary-text-color: #757575;
$divider-color: #bdbdbd;
$light-grey: #efefef;
$grey: #dedede;
